<template>
  <div class="star-pop-edit-container">
    <a-modal
      title="编辑时间"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="500px"
    >

    <div class="time-select">
      星光余额：
      <a-input-number v-model="money" v-if="isEdit" style="width:180px" ></a-input-number> 
      <div v-else class="money">{{row.starlight |money }}</div>
      <le-button
        txt="确定"
        width="40px"
        height="30px"
        fontSize="16px"
        @btnClick="handleOk()"
        style="margin-left: 20px"
        v-if="isEdit"
      />
      <le-button
        txt="修改"
        width="40px"
        height="30px"
        fontSize="16px"
        @btnClick="isEdit=true"
        style="margin-left: 20px"
        v-else
      />
    </div>

<a-alert
    message="输入值是在原基础上增加或减少"
    banner
    closable
  />



    </a-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      confirmLoading: false,
      money:0,
      isEdit:false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  computed: {},
  mounted() {
    // this.money=this.row.starlight
  },
  methods: {
    handleOk(e) {
      this.confirmLoading=true
      this.$api.post(`v1/user/starlight/edit`,{
        uid:this.row.uid,
        starlight:this.money
      }).then(res=>{
        this.confirmLoading=false
        if(res.code==200){
          this.$message.success('编辑成功')
          this.$emit("handleOk");
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleCancel(e) {
      this.$emit("handleCancel");
    },
    onChange(date, dateString) {
      console.log(date, dateString);
    },
    clickEdit(){
      this.$api.post(`v1/user/starlight/edit`,{
        uid:this.row.uid,
        starlight:this.money
      }).then(res=>{
        if(res.code==200){
          this.$message.success('编辑成功')
        }else{
          this.$message.error(res.message)
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.star-pop-edit-container {

}

  .time-select{
    display: flex;
    align-items: center;
    font-size: 20px;
    .money{
      color: #199AEC;
    }
  }


</style>
